export const HALF_LIST = [
  {
    title: "12:01 AM to 11:59 AM",
    value: 1,
  },
  {
    title: "12:01 PM to 11:59 PM",
    value: 2,
  },
];



export function getGoodHalfName(value){
    let ff = HALF_LIST.find(el => el.value == value);
    if(ff){
        return ff.title;
    }else{
        return '';
    }
}
