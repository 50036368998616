import React, { Component } from 'react'
import CourseTable from './RoutineTable';
import CourseAdd from './RoutineAdd';
import { LinearProgress } from '@material-ui/core';
import Snack from '../../Snackbar/Snack';
import { PostData, GetData, PutData, DeleteData } from '../../../api/service';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import SmallBread from '../../SmallBread';



export default class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            isLoading: true,
            status: false,
            message: '',
            table_data: [],
            edit: false,
            edit_data: [],
            payments: [],
            is_all_loaded: false
        }

    }

    __getData() {

        GetData(`/${this.props.apikey}/getusersall`)
        .then((resp) => {
            //console.log(resp)
            this.setState({
                table_data: resp,
                isLoading: false
            })
        })

    }

    componentDidMount() {
        this.__getData();
    }


    onAddClick() {
        this.setState({
            show: !this.state.show,
            edit: false
        })
    }

    setData(data) {
        //send the data to the server

        PostData(`/${this.props.apikey}/${this.props.username}/adduser`, data)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    show: false,
                    status: true,
                    message: resp.message,
                    edit: false
                })
                this.__getData();
            })
    }

    actionClick(el) {
        this.setState({
            edit: true,
            edit_data: el,
            show: true
        })
    }

    onPrintClick(id){
        this.props.history.push('/bills/print', id)
    }

    esetData(data) {
        //send the data to the server

        PutData(`/${this.props.apikey}/${data.id}/updateuser`, data)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    show: false,
                    status: true,
                    message: resp
                })
                this.__getData();
            })
    }

    onDeleteClick(id) {
        this.setState({
            show: false
        })
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: this.onDeleteConfirm.bind(this, id)
                },
                {
                    label: 'No'
                }
            ]
        })
    }

    onDeleteConfirm(id) {
        DeleteData(`/${this.props.apikey}/${this.props.username}/${id}/deleteuser`)
            .then((resp) => {
                this.setState({
                    show: false,
                    status: true,
                    message: 'User Deleted Successfully'
                })
                this.__getData();
            })
    }

    refreshData = () => {
        this.__getData();
    }


    render() {
        return (
            <div>

                <SmallBread
                    data={
                        [
                            {
                                title: 'Users',
                                link: '/users'
                            },
                            {
                                title: 'View',
                                link: ''
                            }
                        ]
                    }
                />


                {this.state.status ? <Snack
                    open={this.state.status}
                    message={this.state.message}
                    handleClose={() =>
                        this.setState({
                            status: false,
                            message: ''
                        })
                    } /> : null}

                <div style={{ padding: '20px' }}>

                    {this.state.isLoading ? <LinearProgress color="secondary" /> : null}


                    <CourseAdd
                        show={this.state.show}
                        handleClose={this.onAddClick.bind(this)}
                        setData={this.setData.bind(this)}
                        edit={this.state.edit}
                        edit_data={this.state.edit_data}
                        esetData={this.esetData.bind(this)}
                        onDeleteClick={this.onDeleteClick.bind(this)}
                        is_all_loaded={this.state.is_all_loaded}
                    />



                    {!this.state.isLoading ? <CourseTable
                   {...this.props}
                        table_data={this.state.table_data}
                        isLoading={this.state.isLoading}
                        onAddClick={this.onAddClick.bind(this)}
                        actionClick={this.actionClick.bind(this)}
                        refreshData={this.refreshData}
                        onPrintClick={this.onPrintClick.bind(this)}  /> : null}

                </div>
            </div>
        )
    }
}
