import React, { Component } from 'react';
import './css/welcome.css';
import { GetData, PostData } from '../../api/service';
import TopLabel from '../DashBoard/TopLabel';
import Stats from '../DashBoard/Stats';


export default class Welcome extends Component {  
  render() {
    return (
      <div>
        <TopLabel username={this.props.username} title={`Overview`}/>
        <Stats
          history={this.props.history}
          username={this.props.username}
          apikey={this.props.apikey}
        />
      </div>
    )
  }
}
