import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import './css/homepage.css';
import Welcome from '../Components/Homepage/Welcome';

import ViewLog from '../Components/Report/ViewLog';
import UsersLanding from '../Components/Users/UsersLanding';
import TreeLanding from '../Components/Tree/TreeLanding';
import PaymentLanding from '../Components/Payments/PaymentLanding';
import IncomeLanding from '../Components/Income/IncomeLanding';
import ReferenceLanding from '../Components/SponsorReferences/ReferenceLanding';
import ViewCalculations from '../Components/Calculations/ViewCalculations';
import ViewPayouts from '../Components/Calculations/ViewPayouts';


class HomePage extends Component {
  constructor(props) {
    super(props);

    let us = JSON.parse(localStorage.getItem('user'));
    let username = us.user.email;

    this.state = {
      username: username,
      apikey: us.user.api_key
    }

  }
  render() {
    return (
      <div>
        <Navbar history={this.props.history} username={this.state.username} />
        <div className="main-body-no-padding">

          <Switch>
            <Route exact path="/" render={(props) =>
              <Welcome
                {...props}
                history={this.props.history}
                username={this.state.username}
                apikey={this.state.apikey} />
            } />


            <Route exact path="/logs" render={(props) =>
              <ViewLog
                {...props}
                history={this.props.history}
                username={this.state.username}
                apikey={this.state.apikey} />
            } />

            <Route exact path="/users" render={(props) =>
              <UsersLanding
                {...props}
                history={this.props.history}
                username={this.state.username}
                apikey={this.state.apikey} />
            } />


            <Route exact path="/tree" render={(props) =>
              <TreeLanding
                {...props}
                history={this.props.history}
                username={this.state.username}
                apikey={this.state.apikey} />
            } />


            <Route exact path="/calculations" render={(props) =>
              <ViewCalculations
                {...props}
                history={this.props.history}
                username={this.state.username}
                apikey={this.state.apikey} />
            } />


<Route exact path="/payouts" render={(props) =>
              <ViewPayouts
                {...props}
                history={this.props.history}
                username={this.state.username}
                apikey={this.state.apikey} />
            } />


            <Route exact path="/payments" render={(props) =>
              <PaymentLanding
                {...props}
                history={this.props.history}
                username={this.state.username}
                apikey={this.state.apikey} />
            } />


            <Route exact path="/income" render={(props) =>
              <IncomeLanding
                {...props}
                history={this.props.history}
                username={this.state.username}
                apikey={this.state.apikey} />
            } />

            <Route exact path="/sponsorreferences" render={(props) =>
              <ReferenceLanding
                {...props}
                history={this.props.history}
                username={this.state.username}
                apikey={this.state.apikey} />
            } />

          </Switch>

        </div>
      </div>
    )
  }
}

export default withAuth(HomePage);