import React from "react";
import SmallBread from "../SmallBread";
import { PostData } from "../../api/service";
import { Button, Card, Empty, Input, Select, Spin, message } from "antd";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { HALF_LIST, getGoodHalfName } from "../../Helper/GlobalConstants";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const { Option } = Select;

export default function ViewCalculations() {
  const [state, setState] = React.useState({
    entry_date: "",
    half_no: "",
  });

  const [error, setError] = React.useState({
    entry_date: "",
    half_no: "",
  });

  function onChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
  }

  const [data, setData] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  function onSubmit() {
    if (state.entry_date != "" && state.half_no != "") {
      // post api
      setIsSubmitting(true);
      PostData(`/get-income-calculations`, state)
        .then((resp) => {
          setData(resp);
          setIsLoaded(true);
          setIsSubmitting(false);
        })
        .catch((err) => {
          message.error(`Error in server`);
          setIsSubmitting(false);
        });
    }
  }

  return (
    <div>
      <SmallBread
        data={[
          {
            title: "Calculations",
            link: "/calculations",
          },
          {
            title: "View",
            link: "",
          },
        ]}
      />

      <br />

      <div style={{ padding: 5 }}>
        <Card>
          <Typography variant="h6" gutterBottom>
            Select the date and half to get the results of the payouts
          </Typography>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <div style={{ fontWeight: 700 }}>
                Select Date <span style={{ color: "red" }}>*</span>
              </div>
              <Input
                type="date"
                value={state.entry_date}
                onChange={(e) => onChange("entry_date", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <div style={{ fontWeight: 700 }}>
                Select Half No <span style={{ color: "red" }}>*</span>
              </div>
              <Select
                style={{ width: "100%" }}
                value={state.half_no}
                onChange={(e) => onChange("half_no", e)}
              >
                {HALF_LIST.map((el, index) => (
                  <Option key={index} value={el.value}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Button
                type="primary"
                loading={isSubmitting}
                onClick={() => onSubmit()}
                style={{ marginTop: 20 }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Card>
      </div>

      {/* Table to view calculation per date and half */}

      <br />

      {isSubmitting && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 100,
          }}
        >
          <Spin />
        </div>
      )}

      {isLoaded && Array.isArray(data) && data.length == 0 && (
        <Card>
          <Empty description="Calculations has not been done yet" />
        </Card>
      )}

      {isLoaded && Array.isArray(data) && data.length > 0 && (
        <Card style={{ overflowX: "scroll" }}>
          <div style={{ textAlign: "center" }}>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table="table-to-xls"
              filename={`Payout Report`}
              sheet="tablexls"
              buttonText="Download as XLS"
            />
          </div>
          <br />

          <table
            size="small"
            className="table table-bordered table-striped table-sm"
            id="table-to-xls"
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 700 }}>Sl</TableCell>
                <TableCell style={{ fontWeight: 700 }}>User ID</TableCell>
                <TableCell style={{ fontWeight: 700 }}>Name</TableCell>
                <TableCell style={{ fontWeight: 700 }}>PAN</TableCell>
                <TableCell style={{ fontWeight: 700 }}>Phone</TableCell>
                <TableCell style={{ fontWeight: 700 }}>Date</TableCell>
                <TableCell style={{ fontWeight: 700 }}>Half No</TableCell>
                <TableCell style={{ fontWeight: 700 }}>
                  Total Left Side
                </TableCell>
                <TableCell style={{ fontWeight: 700 }}>
                  Total Right Side
                </TableCell>
                <TableCell style={{ fontWeight: 700 }}>
                  Flush Out Pairs
                </TableCell>
                <TableCell style={{ fontWeight: 700 }}>
                  Total Flush Out
                </TableCell>
                <TableCell style={{ fontWeight: 700 }}>
                  Total Burn Out
                </TableCell>
                <TableCell style={{ fontWeight: 700 }}>
                  Total Paid Pairs
                </TableCell>
                <TableCell style={{ fontWeight: 700 }}>Total Earning</TableCell>
                <TableCell style={{ fontWeight: 700 }}>Total TDS</TableCell>
                <TableCell style={{ fontWeight: 700 }}>
                  Total Admin Charge
                </TableCell>
                <TableCell style={{ fontWeight: 700 }}>
                  Repurchase Wallet
                </TableCell>
                <TableCell style={{ fontWeight: 700 }}>Total Payout</TableCell>
                <TableCell style={{ fontWeight: 700 }}>Entry Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data) &&
                data.map((el, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{el.user_id}</TableCell>
                    <TableCell>{el.name}</TableCell>
                    <TableCell>{el.PAN}</TableCell>
                    <TableCell>{el.mno}</TableCell>
                    <TableCell>{el.entry_date}</TableCell>
                    <TableCell>{getGoodHalfName(el.half_no)}</TableCell>
                    <TableCell>{el.total_left_side}</TableCell>
                    <TableCell>{el.total_right_side}</TableCell>
                    <TableCell>{JSON.stringify(el.flush_out_pairs)}</TableCell>
                    <TableCell>{el.total_flush_out}</TableCell>
                    <TableCell>{el.total_burn_out}</TableCell>
                    <TableCell>{el.total_paid_pairs}</TableCell>
                    <TableCell>{el.total_earning}</TableCell>
                    <TableCell>{el.total_tds}</TableCell>
                    <TableCell>{el.total_admin}</TableCell>
                    <TableCell>{el.repurchase_wallet}</TableCell>
                    <TableCell>{el.total_payout}</TableCell>
                    <TableCell>{el.entry_time}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </table>
          
        </Card>
      )}
    </div>
  );
}
