import React, { Component } from 'react'
import { withStyles, Typography, TextField, Grid, Card, CardHeader, CardContent, CardActions, Button } from '@material-ui/core';
import { PostData } from '../../api/service';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import AuthService from './../../auth_components/js/AuthService';

const styles = theme => ({
    textStyle: {
        fontWeight: 400
    },
    messageField: {
        width: '100%'
    },
    root: {
        padding: '20px'
    }
})

class AddMoney extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: '',
            confirm_amount: '',
            username: this.props.username,
            error: {}
        }
        this.Auth = new AuthService();
    }

    componentDidMount(){
        
    }

    onRedirect() {

        this.props.history.replace('/');
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleValidation(){
        let {amount, confirm_amount} = this.state;
        let error = this.state.error;
        let isValid = false;

        if(confirm_amount === amount){
            isValid = true;
            error['confirm_amount'] = "";
        }else{
            isValid = false;
            error['confirm_amount'] = "Amount Do not match"
        }

        this.setState({
            error
        })

        return isValid;
    }

    onSubmit = (e) => {
        e.preventDefault();
        if(this.handleValidation()){
            const data = {
                amount: this.state.amount
            }
            PostData(`/${this.props.apikey}/addmoneytowallet`, data)
            .then((resp) => {
              
                    confirmAlert({
                        title: 'Add Money To Wallet',
                        message: resp,
                        buttons: [
                            {
                                label: 'OKAY',
                                onClick: () => this.onRedirect()
                            }
                        ]
                    })
                
            })
        }
        
    }

    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <form onSubmit={this.onSubmit}>
                    <Card>
                        <CardHeader
                            title="Add Money To Wallet"
                        />
                        <CardContent>
                            <Grid container spacing={24}>
                                

                                <Grid item xs={12} lg={4} md={4}>

                                    <TextField
                                        variant="outlined"
                                        label="Amount"
                                        name="amount"
                                        className={classes.messageField}
                                        type="text"
                                        margin="normal"
                                        required
                                        InputProps={{
                                            required: true
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            },
                                            required: true,

                                        }}
                                        value={this.state.amount}
                                        onChange={this.onChange}
                                    />
                                    <span style={{ color: 'red' }}>{this.state.error['amount']}</span>
                                </Grid>

                                <Grid item xs={12} lg={4} md={4}>

                                    <TextField
                                        variant="outlined"
                                        label="Confirm Amount"
                                        name="confirm_amount"
                                        className={classes.messageField}
                                        type="text"
                                        margin="normal"
                                        required
                                        InputProps={{
                                            required: true
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            },
                                            required: true,

                                        }}
                                        value={this.state.confirm_amount}
                                        onChange={this.onChange}
                                    />

                                    <span style={{ color: 'red' }}>{this.state.error['confirm_amount']}</span>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <div style={{textAlign: 'right', width: '100%'}}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit">Submit</Button>
                            </div>
                           
                        </CardActions>
                    </Card>
                </form>

            </div>
        )
    }
}

export default withStyles(styles)(AddMoney);