import React, { Component } from 'react'
import { withStyles, Typography, Snackbar, IconButton, Fade, Grow, Button } from '@material-ui/core';
import { grey, lightBlue } from '@material-ui/core/colors';
import Folder from '@material-ui/icons/Folder'
import Warning from '@material-ui/icons/Warning'
import Close from '@material-ui/icons/Close'
import CloudUpload from '@material-ui/icons/CloudUpload'
import { PostFormData, DeleteData, PostData, GetData } from '../../../api/service';
import Snack from './../../Snackbar/Snack';

const styles = theme => ({
    root: {
        backgroundColor: grey[200],
        boxShadow: '1px 2px 3px rgba(0,0,0,0.1)'
    },
    uploadDiv: {
        minHeight: '30vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonForUpload: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '20px',
        position: 'relative'
    },
    uploadBarDiv: {

        height: '8vh',
        width: '50%',
        borderRadius: '5px',
        boxShadow: '2px 4px 13px rgba(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'row',
        color: 'white',
        alignItems: 'center',
        marginBottom: '1vh'
    },
    buttonIcon: {
        color: 'white'
    },
    firstDiv: {

    },
    fileNameP: {
        flexBasis: '80%'
    },
    fileUploadHidden: {
        opacity: 0,
        position: 'absolute'
    },
    highlightCss: {
        backgroundColor: lightBlue[100]
    }
})

class FileUploadNew extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: this.props.type,
            filename: '',
            file: [],
            errors: [],
            dp: [],
            highlight: false,
            files: [],
            isUploaded: false,
            uploadedFiles: [],
            status: false,
            message: ''
        }

        this.onChange = this.onChange.bind(this);
        this.addFile = this.addFile.bind(this);
        this.fileHandle = this.fileHandle.bind(this);


    }
    __initialise() {
        this.setState({
            type: this.props.type,
            filename: '',
            file: '',
            errors: {},
            isUploaded: false,
            uploadedFiles: []
        })
    }

    __getDocData = () => {
        if(this.props.photo !== undefined){
            this.setState({
                uploadedFiles: this.props.photo,
                isUploaded: true
            })
        }
        
    }

    componentDidMount() {
        this.__initialise();
        this.__getDocData();

        this.setState({
            dp: this.props.dp,
        })

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

    }

    fileHandle(e) {
        let files = this.state.files;
        let f1 = files.concat([...e.target.files]);
        this.setState({
            files: f1,
            errors: [],
            file: e.target.files
        })
    }

    handleValidation() {
        let files = this.state.files;
        let isValid = true;

        let errors = []

        if (files.length === 0) {
            isValid = false;
            errors['error_message'] = "No file chosen"
        }

        this.setState({
            errors
        })

        return isValid;


    }

    addFile = () => {
        
        if (this.handleValidation()) {
            let formdata = new FormData();
            formdata.append('type', this.state.type);
            let index = 0;
            for (const f of this.state.file) {
                formdata.append(`file[${index}]`, f);
                index++;
            }


            //console.log(this.state.file)
            PostFormData(`/addfile`, formdata)
                .then((resp) => {
                    //console.log(resp);
                    let uploadedFiles = this.state.uploadedFiles;
                    let c = uploadedFiles.concat(resp);
                    this.setState({
                        isUploaded: true,
                        uploadedFiles: c,
                        files: []
                    })

                    this.props.onSubmit(c);
                })
        }
    }

    onDelete = (file_id, isUploaded, index) => {
        if (isUploaded) {
            this.onConfirmDelete(file_id, index);
        }
    }

    onNextClick = () => {
        this.props.onSubmit(this.props.doc_id);
    }


    onConfirmDelete(file_id, index) {
        DeleteData(`/${file_id}/deletefile`)
            .then((resp) => {
                if (resp) {
                    let uploadedFiles = this.state.uploadedFiles;
                    uploadedFiles.splice(index, 1);
                    this.setState({
                        uploadedFiles
                    })
                }
            });
    }

    onDragEnter = (e) => {
        e.preventDefault();
        this.setState({
            highlight: true
        })
    }

    onDragLeave = (e) => {
        e.preventDefault();
        this.setState({
            highlight: false
        })
    }

    onDrop = (e) => {
        e.preventDefault();
        let dt = e.dataTransfer;
        let files = dt.files;

        //get the file array
        let file_array = [...files];


        //perform upload task


        let f = this.state.files;

        let f1 = f.concat(file_array);

        //console.log(f1);

        this.setState({
            files: f1
        })
    }
    render() {
        let { classes } = this.props;
        let i = [];
        let j = [];
        if (Array.isArray(this.state.files) && this.state.files.length > 0) {
            let files = this.state.files;
            i = files.map((el, index) =>
                <UploadBar
                    key={index}
                    {...this.props}
                    fileName={el.name}
                    isUploaded={this.state.isUploaded}
                    onDelete={this.onDelete.bind(this)}
                    index={index}
                />
            )
        }

        if (this.state.isUploaded) {
            let uploadedFiles = this.state.uploadedFiles;
            if (Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
                j = uploadedFiles.map((el, index) =>
                    <UploadBar
                        key={index}
                        {...this.props}
                        fileName={el.file_id}
                        isUploaded={this.state.isUploaded}
                        onDelete={this.onDelete.bind(this)}
                        index={index}
                    />
                )
            }
        }
        return (
            <div className={classes.root}>

                {this.state.status ? <Snack
                    open={this.state.status}
                    message={this.state.message}
                    handleClose={() =>
                        this.setState({
                            status: false,
                            message: ''
                        })
                    } /> : null}
                
                    <div
                        className={`${classes.uploadDiv} ${this.state.highlight ? classes.highlightCss : ''}`}
                        onDragEnter={this.onDragEnter}
                        onDragOver={this.onDragEnter}
                        onDragLeave={this.onDragLeave}
                        onDrop={this.onDrop}
                    >

                        
                       
                        <Typography
                            variant="body2"
                            className={classes.buttonForUpload}>
                            <CloudUpload /> &nbsp;&nbsp;Click here to Upload
                        <input
                                type="file"
                                multiple
                                name="file[]"
                                className={classes.fileUploadHidden}

                                onChange={this.fileHandle} />
                        </Typography>
                        {i}
                        {j}

                        {this.state.errors['error_message'] ?
                            <ErrorBar
                                {...this.props}
                                error_message={this.state.errors['error_message']}
                            /> : null}



                        <div >
                            <Button
                                variant="outlined"
                                size="small"
                                type="button"
                                onClick={this.addFile}

                            >
                                Upload
          </Button>


                        </div>

                    </div>
                
            </div>
        )
    }
}

export default withStyles(styles)(FileUploadNew);

export const UploadBar = (props) => {
    let { classes } = props;
    return (
        <Grow in={true} timeout={1000}>
            <div className={classes.uploadBarDiv} style={{ backgroundColor: 'black', }}>
                <IconButton className={classes.firstDiv}>
                    <Folder className={classes.buttonIcon} />
                </IconButton>
                <Typography
                    color="inherit"
                    className={classes.fileNameP}
                >
                    {props.fileName}
                </Typography>
                <div className={classes.rightSide}>
                    <IconButton className={classes.firstDiv}>
                        {props.isUploaded ?
                            <Close
                                className={classes.buttonIcon}
                                onClick={props.onDelete.bind(this, props.fileName, props.isUploaded, props.index)}
                            /> : null}
                    </IconButton>
                </div>
            </div>
        </Grow>
    )
}


export const ErrorBar = (props) => {
    let { classes } = props;
    return (
        <Grow in={true} timeout={500}>
            <div className={classes.uploadBarDiv} style={{ backgroundColor: 'red' }}>
                <IconButton className={classes.firstDiv}>
                    <Warning className={classes.buttonIcon} />
                </IconButton>
                <Typography
                    color="inherit"
                    className={classes.fileNameP}
                >
                    {props.error_message}
                </Typography>
                <div className={classes.rightSide}>
                </div>
            </div>
        </Grow>

    )
}