import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom'
import IncomeView from './view/IncomeView';


const styles = theme => ({

})

class IncomeLanding extends Component {
    render() {
        return (
            <div>

                <Switch>
                    <Route exact path="/income" render={(props) =>
                        <IncomeView
                            {...props}
                            history={this.props.history}
                            username={this.props.username}
                            apikey={this.props.apikey} />
                    } />

                </Switch>
            </div>
        )
    }
}

export default withStyles(styles)(IncomeLanding)