import React, { Component } from 'react'
import { withStyles, Card, CardContent } from '@material-ui/core';
import { GetData } from './../../api/service';
import SmallBread from '../SmallBread';
import { grey } from '@material-ui/core/colors';

const styles = theme => ({
    logBack: {
        backgroundColor: grey[200],
        height: '83vh',
        overflowY: 'scroll'
    },
    lineColor: {
        color: 'gray'
    },
    root: {
        padding: '10px'
    }
})

class ViewLog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            data: []
        }

    }

    __getData = () => {
        GetData(`/${this.props.apikey}/${this.props.username}/log`)
            .then((resp) => {
                this.setState({
                    isLoading: false,
                    data: resp
                })
            })
    }

    componentDidMount() {
        this.__getData();
    }


    render() {
        let { classes } = this.props;
        let i = [];
        if (!this.state.isLoading) {
            let data = this.state.data;
            i = data.map((el, index) =>
                <li className={classes.lineColor} key={index}>{el}</li>
            )
        } else {
            i = <li key="0" className={classes.lineColor}>Loading.....Please wait</li>
        }

        return (
            <div>
                <SmallBread
                    data={
                        [
                            {
                                title: 'Logs',
                                link: '/logs'
                            },
                            {
                                title: 'Log',
                                link: ''
                            }
                        ]
                    }
                />


                <div className={classes.root}>
                    <Card className={classes.logBack}>
                        <CardContent>
                            <ul>
                                {i}
                            </ul>
                        </CardContent>
                    </Card>
                </div>
            </div>
            
        )
    }
}

export default withStyles(styles)(ViewLog)