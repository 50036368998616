import React, { Component } from 'react';
import '../css/Login.css';
import Notifications, { notify } from 'react-notify-toast';
import AuthService from './AuthService';
import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';

import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography, IconButton } from '@material-ui/core';

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loginDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        padding: '20px'
    },
    content: {
        position: 'relative'
    },
    button: {
        backgroundColor: '#2196F3'
    },
    inputText:{
        padding: '15px',
        paddingLeft: '25px',
        border: 'solid thin #F9F9F9',
        borderTopLeftRadius: '30px',
        borderTopRightRadius: '30px',
        borderBottomLeftRadius: '30px',
        borderBottomRightRadius: '30px',
        backgroundColor: '#F9F9F9',
        marginBottom: '20px',
        outline: 'none'
    },
    loginText: {
        marginTop: 10,
        fontSize: 20,
        fontWeight: 500
    }
});

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            isLoading: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.Auth = new AuthService();
    }
    componentWillMount() {
        if (this.Auth.loggedIn())
            this.props.history.replace('/');
    }
    onSubmit(e) {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        this.Auth.login(this.state.username, this.state.password)
            .then(res => {
                let username = this.state.username;
                this.setState({
                    username: "",
                    password: "",
                    isLoading: false
                });
                window.location.reload();
            })
            .catch(err => {
                notify.show('Wrong Username or password', "error", 3000);
                this.setState({
                    isLoading: false
                })
            });
    }
    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }
    render() {
        const { classes } = this.props;
        return (
            <section className={classes.container} >
                <Notifications />

                <div >
                    <Fade in={true} timeout={{ enter: 1500 }}>
                        <section className={classes.loginDiv}>

                        <div align="center">
                                <img
                                    alt="vitasmart"
                                    src={`/log-website-vm.png`}
                                    style={{width: '300px'}}
                                />
                                <Typography
                                    className={classes.loginText}
                                    gutterBottom
                                >
                                   Superadmin Login
                                </Typography>
                            </div>

                            <br />


                            <div>
                                <form onSubmit={this.onSubmit}>

                                    <FormControl fullWidth required>

                                        <input
                                            required
                                            className={classes.inputText}
                                            name="username"
                                            value={this.state.username}
                                            onChange={this.handleChange}
                                            placeholder="Email ID"
                                        />
                                        
                                    </FormControl>

                                    

                                    <FormControl fullWidth required>
                                        <input
                                            required
                                            className={classes.inputText}
                                            type="password"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                            placeholder="Your password here"
                                        />
                                    </FormControl>

                                    <br />

                                    <div style={{ textAlign: 'right' }}>
                                        <Link to="/forgotpassword">
                                            <Typography
                                                variant="caption"
                                            >
                                                Forgot password ?
                                    </Typography>
                                        </Link>
                                    </div>

                                    <br />

                                    <div align="center">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            size="large"
                                            className={classes.button}
                                        >
                                            Login
                                        </Button>
                                    </div>
                                </form>
                            </div>
                            <br />
                            <br />

                            {/* <div align="center">
                                <Typography
                                    variant="caption"
                                >
                                    Don't have an account yet ? <b>Sign Up</b>
                                </Typography>

                            </div> */}





                        </section>
                    </Fade>
                </div>
            </section>
        );
    }
}

export default withStyles(styles)(Login);

export const IconLogin = (props) => {
    let { classes } = props;
    return (
        <div className={classes.iconlogin}>

        </div>
    )
}