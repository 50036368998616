import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton, Dialog, DialogTitle, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';
import Print from '@material-ui/icons/Print';
import { PostData, GetData, PutData } from '../../../api/service';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { confirmAlert } from 'react-confirm-alert';



export default class StockTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false,
            show: false,
            show_PAN:false,
            send_data: '',
            send_data_PAN: '',
            pan_data:[],
            isPanLoaded:false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onPrintClick(id) {
        this.props.onPrintClick(id)
    }

    onClick() {
        console.log("hey");
    }

    getType(value) {
        if (value === 0) {
            return 'FRESH';
        } else {
            return 'RE-PURCHASE';
        }
    }

    getStatus = (value) => {
        if (value === 1) {
            return 'Active';
        } else if (value === 0) {
            return 'Inactive';
        }
    }

    getPanStatus = (value) => {
        if (value === 1) {
            return 'Verified';
        } else if (value === 0) {
            return 'Not verified';
        }
    }

    onActiveClick(el) {
        this.setState({
            show: true,
            send_data: el.user_id
        })
    }

    onPanClick(el) {
        this.setState({
            show_PAN: true,
            send_data_PAN: el.user_id
        })
    }

    handleClose = () => {
        this.setState({
            show: false,
            send_data: '',
            show_PAN: false,
            send_data_PAN: ''
        }, () => {
            this.props.refreshData();
        })
    }

    getPanData(user_id) {
        const d={
            user_id:user_id
        }

        PostData(`/${this.props.apikey}/getpandata`,d)
        .then((resp) => {
            console.log(resp)
            this.setState({
                pan_data: resp[0]['PAN_photo_data'],
                isPanLoaded: true
            })
            return resp;
        }).then((resp)=>{console.log(resp[0].PAN_photo_data[0]);
            if(Array.isArray(resp[0].PAN_photo_data) && resp[0].PAN_photo_data.length > 0){
                let dd = `data:${resp[0].PAN_photo_data[0].mime};base64,${resp[0].PAN_photo_data[0].data}`;
                var image = new Image();
                image.src = dd;
    
            var w = window.open("");
            w.document.write(image.outerHTML);
            }  
            else{
                confirmAlert({
                    title: 'PAN Photo',
                    message: 'PAN Card not uploaded yet',
                    buttons: [
                        {
                            label: 'Okay'
                        }
                    ]
                })
            }         
            
            })

    }




    render() {
        const columns = [
            {
                name: "Sponsor ID",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Dist ID",
                options: {
                    filter: true,
                    sort: true,
                }
            },

            {
                name: "Name",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Phone",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Email",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Current Level",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "PAN",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "PAN Status",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "View",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            },
            {
                name: "Verify",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            },
            
            {
                name: "Status",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Activate/Deactivate",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [ el.sponsor_id,
                    el.user_id,
                    el.name,
                    el.mno,
                    el.email,
                    el.level,
                    el.PAN,
                    this.getPanStatus(el.is_PAN_verified),
                   <VisibilityIcon onClick={this.getPanData.bind(this, el.user_id)}/>,
                   <VerifiedUserIcon onClick={this.onPanClick.bind(this, el)}/>,
                    this.getStatus(el.main_status),
                    <Launch onClick={this.onActiveClick.bind(this, el)} />,
                    <Launch onClick={this.onActionClick.bind(this, el)} />
                ]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Users"}
                    data={data}
                    columns={columns}
                    options={options}

                />

                {this.state.show ? (<DialogStatus
                    open={this.state.show}
                    user_id={this.state.send_data}
                    handleClose={this.handleClose}
                />) : null}
                {this.state.show_PAN ? (<DialogPan
               
                    open={this.state.show_PAN}
                    user_id={this.state.send_data_PAN}
                    handleClose={this.handleClose}
                />) : null}
            </div>
        )


    }
}

class DialogStatus extends Component {


    handleListItemClick = (user_id, value) => {
        let d = {
            user_id: user_id,
            status: value
        }

        GetData(`/deactivateaccount/${user_id}/${value}`, d)
        .then((resp) => {
            //console.log(resp);
            this.props.handleClose();
        })
    }
    render() {
        const stat = [
            {
                title: 'Activate this account',
                value: 1
            },
            {
                title: 'Deactivate this account',
                value: 0
            }
        ]
        return (
            <div>
                <Dialog onClose={this.props.handleClose} open={this.props.open}>
                    <DialogTitle>Set active : {this.props.user_id}</DialogTitle>
                    <List>
                        {stat.map((el,index) => (
                            <ListItem key={index} button onClick={() => this.handleListItemClick(this.props.user_id,el.value)}>
                                <ListItemAvatar>
                                    <Avatar>
                                        {el.value}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={el.title} />
                            </ListItem>
                        ))}
                    </List>
                </Dialog>
            </div>
        )
    }
}


class DialogPan extends Component {


    handlePanClick = (user_id, value) => {
        let d = {
            user_id: user_id,
            status: value
        }

        PutData(`/updatepan`, d)
        .then((resp) => {
            //console.log(resp);
            this.props.handleClose();
        })
    }
    render() {
        const stat = [
            {
                title: 'PAN Details Correct',
                value: 1
            },
            {
                title: 'PAN Details Not Correct',
                value: 0
            }
        ]
        return (
            <div>
                <Dialog onClose={this.props.handleClose} open={this.props.open}>
                    <DialogTitle>Verify : {this.props.user_id}</DialogTitle>
                    <List>
                        {stat.map((el,index) => (
                            <ListItem key={index} button onClick={() => this.handlePanClick(this.props.user_id,el.value)}>
                                <ListItemAvatar>
                                    <Avatar>
                                        {el.value}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={el.title} />
                            </ListItem>
                        ))}
                    </List>
                </Dialog>
            </div>
        )
    }
}
