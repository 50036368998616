import React, { Component } from 'react'
import { withStyles, Card, CardHeader, CardContent, TextField, CardActionArea, Button, Typography } from '@material-ui/core';
import Send from '@material-ui/icons/Send'

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white'

    },
    resize: {
        fontSize: 18
    },
    resizeLable: {
        fontSize: 15,
        fontWeight: 400
    },
    resize: {
        fontSize: 18
    },
    resizeLable: {
        fontSize: 15,
        fontWeight: 400
    },
    forgotCard: {
        width: 400,
        padding: '20px',
    }
})

class FormOne extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: ''
        }

    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        this.props.setEmail(this.state.email);
    }

    render() {
        let { classes } = this.props;
        return (
            <div className={classes.container}>
                <form onSubmit={this.onSubmit}>
                    <Card className={classes.forgotCard}>
                        <CardHeader
                            title="Forgot Password"
                        />
                        <CardContent>

                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                label="Please enter your email id"
                                type="email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                margin="normal"
                                InputProps={{
                                    classes: {
                                        input: classes.resize,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.resizeLable
                                    }
                                }}
                            />

                            <Typography variant="subtitle2" style={{ color: 'red' }}>{this.props.error}</Typography>

                        </CardContent>

                        <CardContent style={{ textAlign: 'right' }}>
                            <Button
                                variant="outlined"
                                type="submit">
                                Next <Send />
                            </Button>
                        </CardContent>
                    </Card>
                </form>
            </div>
        )
    }
}


export default withStyles(styles)(FormOne)