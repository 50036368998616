import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton, Dialog, DialogTitle, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';
import Print from '@material-ui/icons/Print';
import { PostData, GetData, PutData } from '../../../api/service';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import DeleteIcon from '@material-ui/icons/Delete';



export default class StockTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false,
            show: false,
            show_PAN:false,
            send_data: '',
            send_data_PAN: '',
            pan_data:[],
            isPanLoaded:false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onPrintClick(id) {
        this.props.onPrintClick(id)
    }


    getStatus = (value) => {
        if (value === 1) {
            return 'Accepted';
        } else if (value === 0) {
            return 'Pending';
        }
        else if (value === 2) {
            return 'Rejected';
        }
    }


    onPanClick(el) {
        this.setState({
            show_PAN: true,
            send_data_PAN: el.id
        })
    }

    handleClose = () => {
        this.setState({
            show: false,
            send_data: '',
            show_PAN: false,
            send_data_PAN: ''
        }, () => {
            this.props.refreshData();
        })
    }

    getRefPhoto(id) {
        const d={
            id:id
        }

        PostData(`/${this.props.apikey}/getrefphoto`,d)
        .then((resp) => {
            //console.log(resp)
            this.setState({
                pan_data: resp[0]['photo_data'],
                isPanLoaded: true
            })
        }).then(()=>{
            
            })

    }

    deleteRef(id) {

        this.props.onDeleteClick(id);

    }




    render() {
        const columns = [
            

            {
                name: "Name",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Phone",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Email",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Subject",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Request Date",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "View",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            },
            {
                name: "Verify",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            },
            
            {
                name: "Status",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [    el.name,
                    el.mno,
                    el.email,
                    el.subject,
                    el.entry_date,
                   <VisibilityIcon onClick={this.getRefPhoto.bind(this, el.id)}/>,
                   <VerifiedUserIcon onClick={this.onPanClick.bind(this, el)}/>,
                    this.getStatus(el.status),
                    <DeleteIcon onClick={this.deleteRef.bind(this, el.id)} />
                ]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"References"}
                    data={data}
                    columns={columns}
                    options={options}

                />

               
                {this.state.show_PAN ? (<DialogPan
                    apikey={this.props.apikey}
                    open={this.state.show_PAN}
                    id={this.state.send_data_PAN}
                    handleClose={this.handleClose}
                />) : null}
            </div>
        )


    }
}



class DialogPan extends Component {


    handlePanClick = (id, value) => {
        let d = {
            id: id,
            status: value
        }

        PutData(`/${this.props.apikey}/updatereference`, d)
        .then((resp) => {
            //console.log(resp);
            this.props.handleClose();
        })
    }
    render() {
        const stat = [
            {
                title: 'Details can be uploaded on website',
                value: 1
            },
            {
                title: 'Request rejected',
                value: 2
            },
            {
                title: 'Keep pending',
                value: 0
            }
        ]
        return (
            <div>
                <Dialog onClose={this.props.handleClose} open={this.props.open}>
                    <DialogTitle>Verify Event</DialogTitle>
                    <List>
                        {stat.map((el,index) => (
                            <ListItem key={index} button onClick={() => this.handlePanClick(this.props.id,el.value)}>
                                <ListItemAvatar>
                                    <Avatar>
                                        {el.value}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={el.title} />
                            </ListItem>
                        ))}
                    </List>
                </Dialog>
            </div>
        )
    }
}
