import React, { Component } from 'react'
import { withStyles, TextField, Button } from '@material-ui/core';
import TreeComponent from './TreeComponent';
import SmallBread from '../../SmallBread';
import Search from '@material-ui/icons/Search'
import { GetData } from './../../../api/service'

const styles = theme => ({
  container: {
    padding: '20px'
  },
  button: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 1.8
  }
})

class TreeView extends Component {

  state = {
    id: '',
    isLoaded: true
  }


  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      isLoaded: false
    })
  }

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({
      id: this.state.id,
      isLoaded: true
    })
  }




  render() {
    let { classes } = this.props;
    return (
      <div>

        <SmallBread
          data={
            [
              {
                title: 'Tree',
                link: '/tree'
              },
              {
                title: 'View',
                link: ''
              }
            ]
          }
        />

        <form onSubmit={this.onSubmit}>
          <div className={classes.container}>
            <TextField
              label="Enter Distributor Id here"
              className={classes.textField}
              margin="normal"
              variant="filled"
              name="id"
              value={this.state.id}
              onChange={this.onChange}
              required
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              <Search /> Search
          </Button>
          </div>
        </form>

        {this.state.isLoaded ? <TreeComponent
          username={this.props.username}
          id={this.state.id}
          apikey={this.props.apikey}
          name={this.props.name}
        /> : null}
      </div>
    )
  }
}

export default withStyles(styles)(TreeView)