import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import Close from "@material-ui/icons/Close";
import Done from "@material-ui/icons/Done";
import { red, green } from "@material-ui/core/colors";
import { Typography } from "@material-ui/core";

export default class StockTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoaded: false,
    };
  }

  handleDelete(rowsDeleted) {
    console.log(rowsDeleted);
  }

  onActionClick(el) {
    this.props.actionClick(el);
  }

  onPrintClick(id) {
    this.props.onPrintClick(id);
  }

  onClick() {
    console.log("hey");
  }

  getPanStatus(value) {
    if (value === 0) {
      return <Close style={{ color: red[400] }} />;
    } else if (value === 1) {
      return <Done style={{ color: green[400] }} />;
    }
  }

  getTds(value) {
    if (value === 0) {
      return "20%";
    } else if (value === 1) {
      return "5%";
    }
  }

  bankDetails = (el) => {
    if (el.account_no !== "") {
      return `A/C No: ${el.account_no} \n A/C Holder: ${el.account_holder_name} \n Bank: ${el.bank} \n Branch: ${el.branch} \n IFSC: ${el.ifsc_code}`;
    } else {
      return null;
    }
  };

  render() {
    const columns = [
      {
        name: "#",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Associate ID",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Name",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "Amount (Rs)",
        options: {
          filter: true,
          sort: true,
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: false,
      responsive: "scroll",
    };

    let data = [];
    let table_data = this.props.table_data;
    if (table_data.length > 0) {
      data = table_data.map((el, index) => [index + 1, el.user_id, el.name, el.cr_bal]);
    } else {
      data = [];
    }

    return (
      <div>
        <MUIDataTable
          title={"User Wallet Balances"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    );
  }
}
