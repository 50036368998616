import React, { Component } from 'react'
import { withStyles, Card, CardHeader, CardContent, TextField, CardActionArea, Button, Typography } from '@material-ui/core';
import Send from '@material-ui/icons/Send'
import { green, red } from '@material-ui/core/colors';

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',

    },
    resize: {
        fontSize: 18
    },
    resizeLable: {
        fontSize: 15,
        fontWeight: 400
    },
    resize: {
        fontSize: 18
    },
    resizeLable: {
        fontSize: 15,
        fontWeight: 400
    },
    forgotCard: {
        width: 400,
        padding: '20px'
    },
    colorMessage: {
        color: red[300]
    }
})

class FormTwo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            otp: ''
        }

    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        this.props.setOtp(this.state.otp);
    }

    render() {
        let { classes } = this.props;
        return (
            <div className={classes.container}>
                <form onSubmit={this.onSubmit}>
                    <Card className={classes.forgotCard}>
                        <CardHeader
                            title="Forgot Password"
                        />
                        <CardContent>

                            <Typography 
                                variant="body1"
                                className={classes.colorMessage}
                                >
                                {this.props.message}
                            </Typography>

                            <TextField
                                required
                                label="Please enter the OTP"
                                type="number"
                                name="otp"
                                value={this.state.otp}
                                onChange={this.handleChange}
                                margin="normal"
                                InputProps={{
                                    classes: {
                                        input: classes.resize,
                                    },
                                    required: true,
                                    min: 0
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.resizeLable
                                    }
                                }}
                            />

                        </CardContent>

                        <CardContent style={{ textAlign: 'right' }}>
                            <Button variant="contained" type="submit">
                                Next <Send />
                            </Button>
                        </CardContent>
                    </Card>
                </form>
            </div>
        )
    }
}


export default withStyles(styles)(FormTwo)