import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom'
import Users from './View/Users';


const styles = theme => ({

})

class UsersLanding extends Component {
    render() {
        return (
            <div>

                <Switch>
                    <Route exact path="/users" render={(props) =>
                        <Users
                            {...props}
                            history={this.props.history}
                            username={this.props.username}
                            apikey={this.props.apikey} />
                    } />
                </Switch>
            </div>
        )
    }
}

export default withStyles(styles)(UsersLanding)