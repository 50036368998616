import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Card, CardContent, Typography, CardActions, Button, withStyles, CardHeader, Badge, Avatar, Fade, Divider, IconButton, Icon } from '@material-ui/core';
import { blueGrey, purple } from '@material-ui/core/colors';

const styles = theme => ({
    topBar: {
        backgroundColor: blueGrey[50],
        padding: '20px',
        display: 'grid',
        gridTemplateColumns: '1fr 4fr',
        width: '100%'

    },
    textOverview: {
        
    },
    levelStatus: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    levelButton: {
        backgroundColor: purple[500],
        color: 'white',
        padding: '8px',
        borderRadius: '2px',
        cursor: 'pointer',
        boxShadow: '1px 2px 3px rgba(0,0,0,0.1)'
        
    }

});



class TopLabel extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={classes.topBar}>
                    <Typography
                        variant="h5"
                        className={classes.textOverview}
                    >
                        {this.props.title}
                </Typography>
                </div>
            </div >
        )
    }
}


export default withStyles(styles)(TopLabel);