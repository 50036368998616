import React, { Component } from "react";
import { withStyles, Paper, Typography } from "@material-ui/core";
import { blueGrey, blue, purple, grey } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing.unit,
    boxShadow: "none",
    backgroundColor: '#9F52B0',
    display: "flex",
    alignItems: "center",
    minHeight: 60,
    borderRadius: 0
  },
  bread: {
    display: "flex",
    flexDirection: "row",
  },
});

class SmallBread extends Component {
  render() {
    let { classes } = this.props;
    let i = [];

    let data = this.props.data;

    if (Array.isArray(data)) {
      i = data.map((el, index) => {
        if (el.link !== "") {
          return (
            <Link
              key={index}
              to={el.link}
              style={{ color: "white", fontWeight: 600 }}
            >
              {el.title} &nbsp; / &nbsp;
            </Link>
          );
        } else {
          return (
            <Typography
              key={index}
              color="textPrimary"
              style={{ color: "white", fontSize: "0.95em" }}
            >
              {el.title}
            </Typography>
          );
        }
      });
    }

    return (
      <div>
        <Paper className={classes.paper}>
          <Breadcrumb
            separator={<span style={{color: "white"}}>/</span>}
            items={data.map((el) => ({
              title: el.link ? <Link to={el.link} style={{color: "white"}}>{el.title}</Link> : <div style={{color: "white"}}>{el.title}</div>,
            }))}
          />
          {/* <div aria-label="Breadcrumb" className={classes.bread}>
            {i}
          </div> */}
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(SmallBread);
