import React, { Component } from "react";
import CourseTable from "./RoutineTable";
import CourseAdd from "./RoutineAdd";
import {
  LinearProgress,
  Card,
  CardHeader,
  Typography,
  CardContent,
  CircularProgress,
  TextField,
  withStyles,
  Button,
  Grid
} from "@material-ui/core";
import Snack from "../../Snackbar/Snack";
import { PostData, GetData, PutData, DeleteData } from "../../../api/service";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SmallBread from "../../SmallBread";
import Search from "@material-ui/icons/Search";

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing.unit * 2,
  },
  container: {
    padding: theme.spacing.unit,
  },
});

class Payments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      isLoading: true,
      status: false,
      message: "",
      table_data: [],
      edit: false,
      edit_data: [],
      payments: [],
      is_all_loaded: false,
      payment_total: [],
      start_date: "",
      end_date: "",
    };
  }

  __getData(start_date, end_date) {
    let d = {
      start_date: start_date,
      end_date: end_date,
    };

    PostData(`/${this.props.apikey}/getalluserbalance`, d)
      .then((resp) => {
        // console.log(resp)
        return resp;
      })
      .then((resp) => {
        PostData(
          `/${this.props.apikey}/${this.props.username}/payment_total`,
          d
        ).then((res) => {
          // console.log(res)
          this.setState({
            table_data: resp,
            payment_total: res,
            isLoading: false,
          });
        });
      });
  }

  componentDidMount() {
    this.__getData(this.state.start_date, this.state.end_date);
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.__getData(this.state.start_date, this.state.end_date);
  };

  onAddClick() {
    this.setState({
      show: !this.state.show,
      edit: false,
    });
  }

  actionClick(el) {
    this.setState({
      edit: true,
      edit_data: el,
      show: true,
    });
  }

  render() {
    let { classes } = this.props;
    return (
      <div>
        <SmallBread
          data={[
            {
              title: "Payments",
              link: "/payments",
            },
            {
              title: "All",
              link: "",
            },
          ]}
        />

        {/* <form onSubmit={this.onSubmit} className={classes.container}>
                    <div className={classes.container}>
                        <TextField
                            label="Starting Date"
                            className={classes.textField}
                            margin="normal"
                            type="date"
                            variant="outlined"
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                            name="start_date"
                            value={this.state.start_date}
                            onChange={this.onChange}
                            required
                        />

                        <TextField
                            label="Ending Date"
                            className={classes.textField}
                            margin="normal"
                            type="date"
                            variant="outlined"
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                            name="end_date"
                            value={this.state.end_date}
                            onChange={this.onChange}
                            required
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                            type="submit"
                        >
                            <Search /> Search
                            </Button>
                    </div>
                </form> */}

        {this.state.status ? (
          <Snack
            open={this.state.status}
            message={this.state.message}
            handleClose={() =>
              this.setState({
                status: false,
                message: "",
              })
            }
          />
        ) : null}

        <div style={{ padding: "20px" }}>
          {this.state.isLoading ? <LinearProgress color="secondary" /> : null}

          {!this.state.isLoading ? (
            <CourseTable
              table_data={this.state.table_data}
              isLoading={this.state.isLoading}
              onAddClick={this.onAddClick.bind(this)}
              actionClick={this.actionClick.bind(this)}
            />
          ) : null}

          <br />

          {!this.state.isLoading ? (
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <PaymentTotalCard
                  title="Total Amount"
                  payment_total={this.state.payment_total}
                />
              </Grid>
            </Grid>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Payments);

export const PaymentTotalCard = (props) => {
  //console.log(props);
  return (
    <div>
      <Card elevation={1}>
        <CardHeader style={{fontWeight: 700}} title={props.title} />
        <CardContent>
          <Typography variant="h4">&#8377; {props.payment_total}</Typography>
        </CardContent>
      </Card>
    </div>
  );
};
