import React, { Component } from 'react'
import CourseTable from './RoutineTable';
import CourseAdd from './RoutineAdd';
import { LinearProgress } from '@material-ui/core';
import Snack from '../../Snackbar/Snack';
import { PostData, GetData, PutData, DeleteData } from '../../../api/service';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import SmallBread from '../../SmallBread';



export default class Reference extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            isLoading: true,
            status: false,
            message: '',
            table_data: [],
            edit: false,
            edit_data: [],
            payments: [],
            is_all_loaded: false
        }

    }

    __getData() {

        GetData(`/${this.props.apikey}/getreferences`)
        .then((resp) => {
            //console.log(resp)
            this.setState({
                table_data: resp,
                isLoading: false
            })
        })

    }

    componentDidMount() {
        this.__getData();
    }


    onAddClick() {
        this.setState({
            show: !this.state.show,
            edit: false
        })
    }


    actionClick(el) {
        this.setState({
            edit: true,
            edit_data: el,
            show: true
        })
    }



    esetData(data) {
        //send the data to the server

        PutData(`/${this.props.apikey}/updatereference`, data)
            .then((resp) => {
                //console.log(resp);
                this.setState({
                    show: false,
                    status: true,
                    message: resp
                })
                this.__getData();
            })
    }

    onDeleteClick(id) {
        this.setState({
            show: false
        })
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: this.onDeleteConfirm.bind(this, id)
                },
                {
                    label: 'No'
                }
            ]
        })
    }

    onDeleteConfirm(id) {
        DeleteData(`/${this.props.apikey}/${id}/deletereference`)
            .then((resp) => {
                this.setState({
                    show: false,
                    status: true,
                    message: 'Reference Deleted Successfully'
                })
                this.__getData();
            })
    }

    refreshData = () => {
        this.__getData();
    }


    render() {
        return (
            <div>

                <SmallBread
                    data={
                        [
                            {
                                title: 'References',
                                link: '/sponsorreferences'
                            },
                            {
                                title: 'View',
                                link: ''
                            }
                        ]
                    }
                />


                {this.state.status ? <Snack
                    open={this.state.status}
                    message={this.state.message}
                    handleClose={() =>
                        this.setState({
                            status: false,
                            message: ''
                        })
                    } /> : null}

                <div style={{ padding: '20px' }}>

                    {this.state.isLoading ? <LinearProgress color="secondary" /> : null}

                    {!this.state.isLoading ? <CourseTable
                   {...this.props}
                        table_data={this.state.table_data}
                        isLoading={this.state.isLoading}
                        onAddClick={this.onAddClick.bind(this)}
                        actionClick={this.actionClick.bind(this)}
                        onDeleteClick={this.onDeleteClick.bind(this)}
                        refreshData={this.refreshData}
                          /> : null}

                </div>
            </div>
        )
    }
}
