import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import './App.css';
import Login from './auth_components/js/Login';
import HomePage from './Containers/HomePage';
import SettingsContainer from './Containers/SettingsContainer';
import ForgotPasswordContainer from './Containers/ForgotPasswordContainer';
import SendMoneyContainer from './Containers/SendMoneyContainer';
import { toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import AddMoneyContainer from './Containers/AddMoneyContainer';

toast.configure();

class App extends Component {
  render() {
    return (
      <Router history={history} >
        <div>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/forgotpassword" component={ForgotPasswordContainer} />

            

            <Route exact path="/" render={(props) => <HomePage {...props} />} />
            <Route exact path="/users" render={(props) => <HomePage {...props} />} />
           

            <Route exact path="/tree" render={(props) => <HomePage {...props} />} />
            <Route exact path="/calculations" render={(props) => <HomePage {...props} />} />


            <Route exact path="/payouts" render={(props) => <HomePage {...props} />} />
            <Route exact path="/payments" render={(props) => <HomePage {...props} />} />
            
            <Route exact path="/income" render={(props) => <HomePage {...props} />} />
            
            <Route exact path="/payments/print" render={(props) => <HomePage {...props} />} />

            <Route exact path="/sendmoney" component={SendMoneyContainer} />
      
            
            <Route exact path="/logs" render={(props) => <HomePage {...props} />} />
            


            <Route exact path="/settings" component={SettingsContainer} />
            <Route exact path="/addmoneytowallet" component={AddMoneyContainer} />
            <Route exact path="/sponsorreferences" render={(props) => <HomePage {...props} />} />
    
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
