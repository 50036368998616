import React, { Component } from 'react'
import CourseTable from './RoutineTable';
import { LinearProgress, Card, CardHeader, Typography, CardContent, CircularProgress, TextField, withStyles, Button } from '@material-ui/core';
import Snack from '../../Snackbar/Snack';
import { PostData, GetData, PutData, DeleteData } from '../../../api/service';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import SmallBread from '../../SmallBread';
import Search from '@material-ui/icons/Search'

const styles = theme => ({
    button: {
        marginTop: theme.spacing.unit*2
    },
    container: {
        padding: theme.spacing.unit
    }
})

class IncomeView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            isLoading: true,
            status: false,
            message: '',
            table_data: [],
            edit: false,
            edit_data: [],
            payments: [],
            is_all_loaded: false,
            payment_total: [],
            start_date: '',
            end_date: ''
        }

    }

    __getData(start_date, end_date) {

        let d = {
            start_date: start_date,
            end_date: end_date
        }

        
            GetData(`/${this.props.apikey}/getcompanyledger`, d)
                .then((resp) => {
                   // console.log(res)
                    this.setState({
                        table_data: resp,
                        isLoading: false
                    })
                })
    

    }

    componentDidMount() {
        this.__getData(this.state.start_date, this.state.end_date);
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        this.__getData(this.state.start_date, this.state.end_date);
    }

    onAddClick() {
        this.setState({
            show: !this.state.show,
            edit: false
        })
    }

    

    actionClick(el) {
        this.setState({
            edit: true,
            edit_data: el,
            show: true
        })
    }

    

    render() {
        let { classes } = this.props;
        return (
            <div>

                <SmallBread
                    data={
                        [
                            {
                                title: 'Ledger',
                                link: '/income'
                            },
                            {
                                title: 'View',
                                link: ''
                            }
                        ]
                    }
                />



                {/* <form onSubmit={this.onSubmit} className={classes.container}>
                    <div className={classes.container}>
                        <TextField
                            label="Starting Date"
                            className={classes.textField}
                            margin="normal"
                            type="date"
                            variant="outlined"
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                            name="start_date"
                            value={this.state.start_date}
                            onChange={this.onChange}
                            required
                        />

                        <TextField
                            label="Ending Date"
                            className={classes.textField}
                            margin="normal"
                            type="date"
                            variant="outlined"
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                            name="end_date"
                            value={this.state.end_date}
                            onChange={this.onChange}
                            required
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                            type="submit"
                        >
                            <Search /> Search
                            </Button>
                    </div>
                </form> */}

                <div style={{ padding: '20px' }}>

                    {this.state.isLoading ? <LinearProgress color="secondary" /> : null}

                    {!this.state.isLoading ? <CourseTable
                        table_data={this.state.table_data}
                        isLoading={this.state.isLoading}
                        onAddClick={this.onAddClick.bind(this)}
                        actionClick={this.actionClick.bind(this)}
                         /> : null}

                    <br />

                </div>
            </div>
        )
    }
}

export default withStyles(styles)(IncomeView)
