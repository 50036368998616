import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import Close from '@material-ui/icons/Close';
import Done from '@material-ui/icons/Done';
import { red, green } from '@material-ui/core/colors';
import { Typography } from '@material-ui/core';



export default class StockTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onPrintClick(id){
        this.props.onPrintClick(id)
    }

    onClick() {
        console.log("hey");
    }

    getPanStatus(value){
        if(value === 0){
            return <Close style={{color: red[400]}} />;
        }else if(value === 1){
            return <Done style={{color: green[400]}} />;
        }
    }

    

    render() {
        const columns = [
            {
                name: "#",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Date",
                options: {
                    filter: true,
                    sort: true,
                }
            },
          
            {
                name: "Parti",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Dr",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Cr",
                options: {
                    filter: true,
                    sort: true,
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll'
        };

        let data = [];
        let table_data = this.props.table_data;
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [
                    index+1, 
                    
                    el.entry_date, 
                    
                    el.parti,
                    el.cr,
                    el.dr
                ]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Transactions"}
                    data={data}
                    columns={columns}
                    options={options}

                />
            </div>
        )


    }
}
